function scrollToo ($e, duration, y) {
    y = y || 0;
    duration = duration || 300;
    var _top = $e.offset().top + y;
    $('html,body').animate({"scrollTop" : _top}, duration);
    return false;
}

function placeholder () {
    var fields = $('.forma__inp .itext, .forma__inp textarea');

    fields.each(function() {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
    });

    $('body').on('keyup change', '.forma__inp .itext, .forma__inp textarea', function () {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
        else $(this).parent().find('label').show();
    }).on('focusin', '.forma__inp .itext, .forma__inp textarea', function () {
        $(this).parent().addClass('forma__inp_light');
    }).on('focusout', '.forma__inp .itext, .forma__inp textarea', function () {
        $(this).parent().removeClass('forma__inp_light');
        // if ($(this).val() == '') $(this).parent().removeClass('forma__inp_focus');
    });

    // fields.focusin(function () {
    //     $(this).parent().addClass('forma__inp_focus forma__inp_light');
    // }).focusout(function () {
    //     $(this).parent().removeClass('forma__inp_light');
    //     if ($(this).val() == '') $(this).parent().removeClass('forma__inp_focus');
    // });
}

/*********************************
********* + Menu mobile **********
*********************************/

function menuToggle () {
    var body = $('body');
    var menu = $('#mm');
    var toggleBut = $('.menu-toggle');
    var plusBut = $('#mm .plus');

    toggleBut.click(function () {
        if (menu.css('display') == 'block') {
            menu.slideUp(300, 'linear');
            toggleBut.removeClass('active');
        }
        else {
            menu.slideDown(300, 'linear');
            toggleBut.addClass('active');
        }
    });

    plusBut.click(function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).next('.subs').slideUp(300, 'linear');
        } else if (!$(this).next().is('.subs:animated')) {
            $(this).addClass('active');
            $(this).next('.subs').slideDown(300, 'linear');
        }
    })

    $(window).resize(function() {
        setTimeout(function() {
            if (window.innerWidth >= 769) {
                menu.show();
                menu.find('.subs').each(function () { $(this).show(); })
            } else {
                if (!toggleBut.hasClass('active')) menu.hide();
                plusBut.each(function () {
                    if (!$(this).hasClass('active')) $(this).next('.subs').hide();
                });
            }
        }, 50);
    });
}

/********************************
******** END Menu mobile ********
********************************/

function execGallery() {
    $('.popup-gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		tLoading: 'Загружено #%curr%...',
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
            navigateByImgClick: true,
            tCounter: '<span class="mfp-counter">%curr% из %total%</span>', // markup of counter
			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
		},
		image: {
			tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
			titleSrc: function(item) {
				return item.el.attr('title') + '<small></small>';
			}
		}
	});
}

function showMagnImage() {
    $('.image-popup-vertical-fit').magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		mainClass: 'mfp-img-mobile',
		image: {
			verticalFit: true
		}
		
	});
}


var sort = function() {
    sort.forma = $('.sort-box form');
    sort.items = $('.other-sort .item-filter a');
    sort.list = $('.filter-list');
    sort.buttons = $('.filter-list .button');
    sort.costSlider = $('.cost-slider');
    sort.inputFrom = $('[name = priceFrom]');
    sort.inputTo = $('[name = priceTo]');
    sort.rangeStart =  Number($('meta[name="catalog:cost-min"]').attr('content'));
    sort.rangeEnd =  Number($('meta[name="catalog:cost-max"]').attr('content'));
    sort.rangeMin =  Number($('meta[name="catalog:cost-min"]').attr('content'));
    sort.rangeMax =  Number($('meta[name="catalog:cost-max"]').attr('content'));
    sort.rangeId = sort.costSlider.attr('id');
    sort.inputSort = $('[name = sorting');
    sort.listSort = $('.sorting');

    sort.pageOptionNovoItem = $('body').hasClass('option-novo-item') ? 1 : 0;

    sort.pageOptionNovoItem ? sort.novostroyExec() : sort.exec();
}


sort.submitForm = function () {
    sort.buttons.click(function () {
        //
    });
}

sort.cleanFilter = function () {
    sort.forma.find('.clean').click(function() {
        $(this).parent().find('.filter-list input:checked').each(function() {
            $(this).attr('checked', false);
        });

        if ($(this).parent().find('.cost-slider').length) {
            sort.inputFrom.val(sort.rangeMin);
            sort.inputTo.val(sort.rangeMax);
        }
        $(this).parent().find('.filter-list .button').trigger('click');
    });
}

sort.filterList = function () {
    sort.items.on('click', function() {
        if (!$(this).hasClass('show-menu-item')) {
            if (sort.forma.find('.show-menu-item').length) {
                sort.forma.find('.show-menu-item').next().slideUp();
                sort.forma.find('.show-menu-item').removeClass('show-menu-item');
            }
            $(this).addClass('show-menu-item');
            $(this).next().slideDown();
        } else {
            $(this).removeClass('show-menu-item');
            $(this).next().slideUp();
        }
        return false;
    });
};

sort.setSliderParams = function (urlParamsObject) {
    var inputFromName = sort.inputFrom.attr('name');
    var inputToName = sort.inputTo.attr('name');
	var start = urlParamsObject[inputFromName];
    var end = urlParamsObject[inputToName];

    if (start != sort.rangeStart || end != sort.rangeEnd) {
        sort.rangeStart = Number(start.split('+').join(''));
        sort.rangeEnd = Number(end.split('+').join(''));
    }
}

sort.getAllUrlParams = function () {
    var uri = decodeURI(location.search.substr(1));
    var chunks = uri.split('&');
    var params = Object();

    for (var i=0; i < chunks.length; i++) {
        var chunk = chunks[i].split('=');
        if(chunk[0].search("\\[\\]") !== -1) {
            if( typeof params[chunk[0]] === 'undefined' ) {
                params[chunk[0]] = [chunk[1]];
            } else {
                params[chunk[0]].push(chunk[1]);
            }
        } else {
            params[chunk[0]] = chunk[1];
        }
    }

    return params;
}

sort.sortTypeSelection = function () {
	var valueSort = sort.inputSort.val();

	sort.listSort.find('[data-value=' + valueSort + ']').addClass('sel');

	sort.listSort.find('span').on('click', function() {
		if (!$(this).hasClass('sel')) {
			sort.inputSort.val($(this).data('value'));
			sort.listSort.find('.sel').removeClass('sel');
			$(this).addClass('sel');
			if (!sort.pageOptionNovoItem) sort.forma.submit();
            else sort.novostroyGetObjects();
		}
	});
}

sort.novostroyReset = function () {
    var but = $('.other-sort .reset');

    but.on('click', function() {
        $(this).parents('.filter-list').find('.itext').each(function() {
            $(this).val('').trigger('keyup');
        });
    });
}

sort.novostroyPrice = function () {
    var inpPrice = $('.tabs').find('.itext');
    var help = $('.tabs').find('.help-column div');

    inpPrice.on('focusin', function() {
        !$(this).parent().index() ?
        $(this).parents('.tabs-item').find('.help').addClass('help_from') :
        $(this).parents('.tabs-item').find('.help').removeClass('help_from');
    });

    help.on('click', function() {
        var price = $(this).data('value');
        var index = $(this).parent().index();
        var inp = $(this).parents('.tabs-item').find('.forma__inp').eq(index);

        inp.find('.itext').val(price).trigger('change');
    });
}

sort.novostroySelectedOptions = function (inpFrom, divider) {
    var str = '';
    var inpTo = inpFrom.parent().next().find('.itext');
    var title = inpFrom.parents('.forma__inps').data('str');

    if (inpFrom.val() && inpFrom.val() != 0) {
        if (!divider) {
            str = inpTo.val() && inpTo.val() != 0 ? 
            title +' ' + inpFrom.val() + '–'+ inpTo.val() : 
            title +' ' + inpFrom.data('str') + ' ' + inpFrom.val();
        } else {
            str = inpTo.val() && inpTo.val() != 0 ? 
            title +' ' + parseInt(inpFrom.val()) / divider + '–'+ parseInt(inpTo.val()) / divider : 
            title +' ' + inpFrom.data('str') + ' ' + parseInt(inpFrom.val()) / divider;
        }
    } else {
        if (!divider) {
            str = inpTo.val() && inpTo.val() != 0 ? 
            title + ' ' + inpTo.data('str') + ' '+ inpTo.val() : 
            '';
        } else {
            str = inpTo.val() && inpTo.val() != 0 ? 
            title + ' ' + inpTo.data('str') + ' '+ parseInt(inpTo.val()) / divider : 
            '';
        }
    }

    return str;
}

sort.novostroyInputStrConstr = function (item) {
    var arr = [];
    var cell = item.find('a');
    item.find('.forma__inps').each(function() {
        if ($(this).hasClass('forma__inps_price')) var divider = parseInt($(this).data('divider'));
        var field = $(this).find('.forma__inp').eq(0);
        var str = divider ? sort.novostroySelectedOptions(field.find('.itext'), divider) : sort.novostroySelectedOptions(field.find('.itext'));
        if (str != '') arr.push(str += ' ' + $(this).data('unit'));
    });
    var result = arr.join('; ').trim();
    result != '' ? cell.text(result) : cell.text(cell.data('default'));
}

sort.novostroySort = function () {
    // var objects = $('.novostroy__list');
    var checkboxList = $('.sort-box');
    var itext = $('.filter-list .itext');
    var timeout;

    checkboxList.find('input:checkbox').on('change', function() {
        var arr = [];
        var box = $(this).parents('.filter-list');
        box.find('input:checkbox:checked').each(function() {
            /*var dataCheckbox = $(this).attr('name');
            var valCheckbox = $(this).val();*/
            arr.push($(this).data('str'));
            /*objects.find('.novostroy__list__item').each(function() {
                if ($(this).data(dataCheckbox) == valCheckbox) $(this).addClass('vis').show();
            });*/
        });
        /*box.find('input:checkbox:not(:checked)').each(function() {
            var dataCheckbox = $(this).attr('name');
            var valCheckbox = $(this).val();
            objects.find('.novostroy__list__item').each(function() {
                if ($(this).data(dataCheckbox) == valCheckbox) $(this).removeClass('vis').hide();
            });
        });
        if (!box.find('input:checkbox:checked').length) {
            objects.find('.novostroy__list__item').each(function() {
                $(this).show();
            });
            objects.find('.novostroy__list__item_none').hide();
        } else {
            objects.find('.vis').length ? 
            objects.find('.novostroy__list__item_none').hide() : 
            objects.find('.novostroy__list__item_none').show();
        }*/
        var str = arr.join(', ').trim();
        str != '' ? box.prev().text(str) : box.prev().text(box.prev().data('default'));
        sort.novostroyGetObjects();
    });

    itext.on('change keyup', function() {
        /*var id = $(this).attr('id');
        var str = id.substring(id.length - 4);
        var val = $(this).val();
        itext.each(function() {
            if ($(this).val()) {
                var id = $(this).attr('id');
                var str = id.substring(id.length - 4);
                var val = $(this).val();

                if (str == 'from') {
                    objects.find('.novostroy__list__item').each(function() {
                        parseInt($(this).data(id)) >= parseInt(val) ? $(this).show().addClass('vis') : $(this).removeClass('vis').hide();
                    });
                } else {
                    objects.find('.novostroy__list__item').each(function() {
                        parseInt($(this).data(id)) <= parseInt(val) ? $(this).show().addClass('vis') : $(this).removeClass('vis').hide();
                    });
                }
            }
        });*/
        var field = $(this);
        clearTimeout(timeout);
        timeout = setTimeout(function() {
            sort.novostroyGetObjects();
            sort.novostroyInputStrConstr(field.parents('.item-filter'));
        }, 500);

        /*objects.find('.vis').length ? 
        objects.find('.novostroy__list__item_none').hide() : 
        objects.find('.novostroy__list__item_none').show();*/
    });
}

sort.novostroyGetObjects = function () {
    var objects = $('.novostroy__list');
    var url = $('meta[name="catalog:get-objects"]').attr('content');
    var params = sort.forma.serialize();

    $.ajax({
        url: url,
        method: 'GET',
        data: params,
        dataType: 'html',
        success: function (data) {
            // callback(JSON.parse(data));
            objects.html(data);
        }
    });
}

sort.novostroyPages = function () {
    var pages = $('.pagination');
    var inp = $('#pageNum');

    // pages.find('li').eq(inp.val()).addClass('active');

    pages.on('click', '.page-link', function(e) {
        if (!$(this).parent().hasClass('active') && !$(this).parent().hasClass('disabled')) {
            var index = $(this).parent().index();
            var activeIndex = pages.find('.active').index();

            pages.find('.active').removeClass('active');

            if (!index) activeIndex --;
            else if (index == (pages.find('li').length - 1)) activeIndex ++;
            else activeIndex = index;
            pages.find('li').eq(activeIndex).addClass('active');

            activeIndex == 1 ? pages.find('li:first-child').addClass('disabled') : pages.find('li:first-child').removeClass('disabled');

            activeIndex == (pages.find('li').length - 2) ? pages.find('li:last-child').addClass('disabled') : pages.find('li:last-child').removeClass('disabled');

            inp.val(activeIndex);

            sort.novostroyGetObjects();
        }
        e.preventDefault();
    });
}

sort.novostroyExec = function () {
    sort.novostroyGetObjects();
    sort.novostroyPages();
    sort.sortTypeSelection();
    sort.novostroySort();
    sort.filterList();
    sort.novostroyPrice();
    sort.novostroyReset();
}

sort.exec = function () {
    sort.minCostName = sort.inputFrom.attr('id');
    sort.maxCostName = sort.inputTo.attr('id');

    sort.filterList();
    sort.cleanFilter();
    sort.submitForm();
    sort.sortTypeSelection();

    var urlParamsObject = sort.getAllUrlParams();

	$.each(urlParamsObject, function(name, value) {
        if( typeof value !== 'object' ) {
            sort.forma.find('[name = "'+decodeURIComponent(name)+'"][value = "'+decodeURIComponent(value)+'"]').prop('checked', true);
        } else {
            $.each(sort.forma.find('[name = "'+decodeURIComponent(name)+'"]'), function() {
                if (value.indexOf($(this).val()) !== -1) $(this).prop('checked', true);
            });
        }
    });

    //if (!$.isEmptyObject(urlParamsObject)) sort.setSliderParams(urlParamsObject);

    if (urlParamsObject[sort.inputFrom.attr('name')]) sort.setSliderParams(urlParamsObject);

    if (sort.rangeStart != sort.rangeMin || sort.rangeEnd != sort.rangeMax) {
        sliderCost(sort.rangeId, sort.minCostName, sort.maxCostName, sort.rangeMin, sort.rangeMax, sort.rangeStart, sort.rangeEnd);
        sort.costSlider.parents('.item-filter').addClass('sel-item');
    } else {
        sliderCost(sort.rangeId, sort.minCostName, sort.maxCostName, sort.rangeMin, sort.rangeMax);
        sort.costSlider.parents('.item-filter').removeClass('sel-item');
    }

    sort.list.each(function() {
        if ($(this).find('input:checked').length && !$(this).parent().hasClass('sel-item')) {
            $(this).parent().addClass('sel-item');
            $(this).parent().find('a sup').text($(this).find('input:checked').length);
        }
    });
}

/***************************************************************************/
/**************************** PRICE SLIDER *********************************/
/***************************************************************************/
function sliderCost(sliderID, minCostName, maxCostName, minCost, maxCost, costStart, costEnd) {
    var minVal = minCost;
    var maxVal = maxCost;
    var stepCost = Number($('meta[name="catalog:cost-step"]').attr('content'));

    costStart = costStart || minCost;
    costEnd = costEnd || maxCost;

    var sliderElement = document.getElementById(sliderID);
    var minElement = document.getElementById(minCostName);
    var maxElement = document.getElementById(maxCostName);
    var inputs = [minElement, maxElement];

    noUiSlider.create(sliderElement, {
    	start: [costStart, costEnd],
    	connect: true,
        step: stepCost,
    	range: {
    		'min': minVal,
    		'max': maxVal
    	},
        format: wNumb({
    		decimals: 0,
	        thousand: ' '
    	})
    });

    sliderElement.noUiSlider.on('update', function( values, handle ) {
    	inputs[handle].value = values[handle];
    });

    function setSliderHandle(i, value) {
    	var r = [null,null];
    	r[i] = value;
    	sliderElement.noUiSlider.set(r);
    }

    // Listen to keydown events on the input field.
    inputs.forEach(function(input, handle) {

    	input.addEventListener('change', function(){
    		setSliderHandle(handle, this.value);
    	});
    });
}

function costSliderSelectionExec () {
    var costMin = Number($('meta[name="catalog:cost-min"]').attr('content'));
    var costMax = Number($('meta[name="catalog:cost-max"]').attr('content'));

    sliderCost('costSlider', 'costMin', 'costMax', costMin, costMax);
}


var catalog = function () {
    catalog.box = $('#catalog .box');
    catalog.moreBut = $('#catalog .more');
	catalog.html = '<div class="one-block"><a href=""><span class="row"><img src="" alt="" /><span class="about"></span></span></a><div class="views"></div></div>';
	catalog.tmp = [];
    catalog.count = 0;
    catalog.storageUrl = $('meta[name="catalog:get-objects"]').attr('content');
    catalog.filterForm = $('.sort-box form');
    catalog.current_page = 0;
    catalog.tmpl = '<div class="one-block"><a href="/cat/obj/{NUMB}/"><span class="row">{COVER}<span class="about">{TABLE}</span></a></div>';

    catalog.exec();
}

catalog.replace = function (object) {
    for (var _i in object.newstr) {
        object.str = object.str.replace(new RegExp('{'+_i+'}','gi'), object.newstr[_i]);
    }
    return object.str;
};

catalog.construct = function (data) {
    //catalog.count += data.length;
    //catalog.paginate(data);
    //if (data['total'] !== undefined) catalog.total = data['total'];

    for (var key in data['data']) {
        var product = data['data'][key];
        if (key == 0) console.log(product);
        var _j = 0;
        var _pars = 0;
        $this = $(catalog.html);
        catalog.box.append($this);
        $this.find('a').attr('href', product.link);
        $this.find('img').attr('src', product.img);

        //@if($item->cover)<img src="/storage/uploads/obj/s/{{ $item->cover->img }}" alt="">@endif

        $this.find('.views').text(product.views2);
        var _about = $this.find('.about');
        if (product.mg != '') _about.prepend('<span class="mg"></span>');
        if (product.ad != '') _about.prepend('<span class="ad">'+product.ad+'</span>');

        /*for (var _i in product.table) {
            if (product.table[_i] != '') {
                _about.append(catalog.replace({str : catalog.tmp[_j], newstr : product.table}));
                _pars ++;
            }
            if (!(_pars % 2)) _about.append('<span class="sep"></span>');
            _j ++;
        }*/
    }

    /*if (_sum - catalog.count == 0) {
        catalog.moreBut.remove();
        $('#catalog').find('.preload').remove();
    }*/
};

catalog.more = function () {
    catalog.moreBut.click(function() {
        if (!$(this).parent().find('.preload').length) $(this).before('<div class="preload"><img src="/assets/img/loader.gif" alt="" /></div>');
        else $(this).parent().find('.preload').show();
        catalog.getObjects();
    });
};

catalog.getObjects = function () {
    //, 'url' : window.location.href
    params = catalog.filterForm.serialize() + '&count=' + catalog.count;
    catalog.ajx({
        data : params,
        success : function (data) {
            $('#catalog').find('.preload').hide();
            catalog.construct(data);
        }
    });
};

catalog.ajx = function (obj) {
    $.ajax($.extend({}, {
        url : catalog.storageUrl,
        dataType : 'json',
        cache : false,
        type : 'get'
    }, obj));
};

catalog.exec = function () {
    catalog.tmp.push('<span class="icon"><span></span></span><span class="inf"><span class="one"><span>{PLACE}</span></span></span>');
    catalog.tmp.push('<span class="icon floor"><span></span></span><span class="inf"><span class="one"><span>{FLOOR}</span></span></span>');
    catalog.tmp.push('<span class="icon room"><span></span></span><span class="inf"><span class="one"><span>{ROOM}</span></span></span>');
    catalog.tmp.push('<span class="icon year"><span></span></span><span class="inf"><span class="one"><span>{YEAR}</span></span></span>');
    catalog.tmp.push('<span class="icon size"><span></span></span><span class="inf"><span class="one"><span>{SIZE}</span></span></span>');
    catalog.tmp.push('<span class="icon tel"><span></span></span><span class="inf"><span class="one phone"><span>{TEL}</span></span></span>');
    catalog.tmp.push('<span class="icon cost"><span></span></span><span class="inf"><span class="one price"><span>{COST}  руб.</span></span></span>');

    $(document).on('click', '#first_page', function () {
        catalog.current_page = 1;
        catalog.getObjects();
        return false;
    });

    $(document).on('click', '#last_page', function () {
        catalog.current_page = catalog.last_page;
        catalog.getObjects();
        return false;
    });

    $(document).on('click', '.page-num', function () {
        catalog.current_page = parseInt($(this).text());
        catalog.getObjects();
        return false;
    });

    catalog.getObjects();
    catalog.more();
}

function execCallForm(forma) {
    // var forma = $('.callForm');
    var but = forma.find('.button_submit');
    new CheckForms(forma, but);
}

function layoutView () {
    $('.novostroy__list').magnificPopup({
        delegate: '.novostroy__list__item__link',
		type: 'ajax',
		alignTop: true,
		overflowY: 'scroll', // as we know that popup content is tall we set scroll overflow by default to avoid jump
        callbacks: { 
            ajaxContentAdded: function() {
                // console.log(this.content.find('.callForm').html());
                execCallForm(this.content.find('.callForm'));
            }
        }
	});
}

function callRequest () {
    $(('.callForm')).each(function() {
        execCallForm($(this));
    });

    $('body').on('click', '.button_callRequest', function(e) {
        $(this).hide().next().show();
        e.preventDefault();
    });
}

/********************************
********** Form Valid ***********
********************************/

function sendMsg () {
    var popup = $('.fixPopupMsg');
    var msg = $('.forma__sendMsg');
    var closeBut = $('.fixPopupMsg__close');
    
    var timeout;
    if (msg.find('div').length) {
        var classMsg = msg.find('div').attr('class');
        var textMsg = msg.find('div').text();
        popup.prepend('<div class="'+classMsg+'">'+textMsg+'</div>');
        popup.addClass('vis');
        timeout = setTimeout(function() {
            popup.animate({opacity : 'hide'}, 300, function() {
                $(this).removeClass('vis');
            });
        }, 5000);
        //popup.prepend('div class="'+classMsg+'"></div>')
    }

    closeBut.on('click', function() {
        clearTimeout(timeout);
        popup.animate({opacity : 'hide'}, 300, function() {
            $(this).removeClass('vis');
        })
    });
}

var CheckForms = function (form, button, options) {
    this.form = form;
    this.button = button;

    this.options = $.extend({}, CheckForms.Defaults, options);

    /*if (this.options.agreement != 1) {
        this.form.data('agreement', 0);
        this.agreementChange();
    } else this.form.data('agreement', 1);*/

    if (this.options.checkboxes) {
        for (var i in this.options.checkboxes) {
            this.switchesChange(this.form.find('#'+this.options.checkboxes[i]+' input:checkbox'));
        }
        this.form.data('checkbox', 0);
    } else this.form.data('checkbox', 1);

    if (this.options.radio) {
        for (var i in this.options.radio) {
            this.switchesChange(this.form.find('[name="'+this.options.radio[i]+'"]'));
        }
        this.form.data('radio', 0);
    } else this.form.data('radio', 1);

    this.checkEmpty();
    this.submitForm();
    this.removeError();
}

CheckForms.Defaults = {
    // agreement: 1,
    checkboxes: 0,
    radio: 0,
}

CheckForms.prototype.checkEmpty = function () {
    this.form.find('.requiredField').each(function () {
        if ($(this).val() == '' || $(this).val() == $(this).parent().find('label').text()) $(this).addClass('emptyField');
    });

    this.checkAllElements();
}

CheckForms.prototype.checkCorrect = function () {
    // /^[0-9a-z-\.]+\@[0-9a-z-]{2,}\.[a-z]{2,}$/i) - email
    var allowedCharEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;

    this.form.find('.requiredField').each(function () {
        if ($(this).attr('name') == 'email' && !allowedCharEmail.test($(this).val())) $(this).addClass('errorField');
        if (!$(this).val()) $(this).addClass('errorField');
    });

    this.checkAllElements();
}

CheckForms.prototype.removeError = function () {
    var allowedCharEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;

    this.form.on('keyup blur', '.requiredField', function () {
        if ($(this).val() && $(this).val() != $(this).parent().find('label').text()) {
            if ($(this).attr('name') == 'email' && allowedCharEmail.test($(this).val())) $(this).removeClass('errorField emptyField');
            else if ($(this).attr('name') != 'email') $(this).removeClass('errorField emptyField');
        } else $(this).addClass('emptyField');

        if (!$(this).parents('form').find('.emptyField').length && !$(this).parents('form').find('.errorField').length && $(this).parents('form').data('agreement')) $(this).parents('form').find('.button_submit').removeClass('button_disabled');
        else $(this).parents('form').find('.button_submit').addClass('button_disabled');
    });
}

CheckForms.prototype.checkCheckboxes = function (id) {
    var elements = $('#'+id).find('input:checkbox');
    for (var i=0; i < elements.length; i++)  {
        if (elements[i].checked) return true;
    }
    return false;
}

CheckForms.prototype.checkCheckboxesAll = function () {
    if (this.options.checkboxes) {
        for (var i in this.options.checkboxes) {
            if (!this.checkCheckboxes(this.options.checkboxes[i])) return false;
        }
        return true;
    }
}

CheckForms.prototype.checkRadio = function (name) {
    var elements = document.getElementsByName(name);
    for (var i=0; i < elements.length; i++)  {
        if (elements[i].checked) return true;
    }
    return false;
}

CheckForms.prototype.checkRadioAll = function () {
    if (this.options.radio) {
        for (var i in this.options.radio) {
            if (!this.checkRadio(this.options.radio[i])) return false;
        }
        return true;
    }
}

CheckForms.prototype.switchesChange = function (element) {
    element.change(function () {
        this.checkAllElements();
        //this.checkEmpty();
        //if (this.form.find('.errorField').length) this.checkCorrect();
    }.bind(this));
}

/*CheckForms.prototype.agreementChange = function () {
    this.options.agreement.change(function () {
        this.options.agreement.is('input:checked') ? this.form.data('agreement', 1) : this.form.data('agreement', 0);
        this.checkAllElements();
    }.bind(this));
}*/

CheckForms.prototype.checkAllElements = function () {
    if (this.options.checkboxes) {
        this.checkCheckboxesAll() ? this.form.data('checkbox', 1) : this.form.data('checkbox', 0);
    }
    if (this.options.radio) {
        this.checkRadioAll() ? this.form.data('radio', 1) : this.form.data('radio', 0);
    }
    if (!this.form.find('.emptyField').length && !this.form.find('.errorField').length && this.form.data('checkbox') && this.form.data('radio')) this.button.removeClass('button_disabled');
    // if (this.form.data('agreement') && !this.form.find('.emptyField').length && !this.form.find('.errorField').length) this.button.removeClass('button_disabled');
    else this.button.addClass('button_disabled');
}

CheckForms.prototype.submitForm = function () {
    if (!this.button.is('a')) {
        this.form.submit(function (e) {
            this.checkEmpty();
            this.checkCorrect();
            if (this.form.find('.emptyField').length || this.form.find('.errorField').length || !this.form.data('checkbox') || !this.form.data('radio')) e.preventDefault();
        }.bind(this));
    } else {
        this.button.click(function (e) {
            this.checkEmpty();
            this.checkCorrect();
            if (this.form.find('.emptyField').length || this.form.find('.errorField').length || !this.form.data('checkbox') || !this.form.data('radio')) e.preventDefault();
            else this.form.submit();
            return false;
        }.bind(this));
    }
}

function execFreeConsForm() {
    var forma = $('#freeConsForm');
    var but = forma.find('.button_submit');
    // var options = {
    //     agreement: $('[name = "agreement"]'),
    // };
    new CheckForms(forma, but);
}

function execEnrollForm() {
    var forma = $('#enrollForm');
    var but = forma.find('.button_submit');
    var enrollForm = new CheckForms(forma, but);
}

function execMortgageForm() {
    var forma = $('#mortgageForm');
    var but = forma.find('.button_submit');
    var mortgageForm = new CheckForms(forma, but);
}

function execOrderForm() {
    var forma = $('#orderForm');
    var but = forma.find('.button_submit');
    var orderForm = new CheckForms(forma, but);
}

function execOrderCalcForm() {
    var forma = $('#orderCalcForm');
    var but = forma.find('.button_submit');
    var orderCalcForm = new CheckForms(forma, but);
}

function execOrderSaleForm() {
    var forma = $('#orderSaleForm');
    var but = forma.find('.button_submit');
    var freeConsForm = new CheckForms(forma, but);
}

function execCatalogForm() {
    var forma = $('#catalogForm');
    var but = forma.find('.button_submit');
    var catalogForm = new CheckForms(forma, but);
}

function execSelectForm() {
    var forma = $('#selectForm');
    var but = forma.find('.button_submit');
    var options = {
        checkboxes: ['layout'],
        radio: ['type']
    };
    var selectForm = new CheckForms(forma, but, options);
}

function execFeedForm() {
    var forma = $('#feedForm');
    var but = forma.find('.button_submit');
    var feedForm = new CheckForms(forma, but);
}

function popupFreeConsForm() {
    $('.popupFreeConsForm').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#name',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#name';
				}
			}
		}
    });
}

function popupEnrollForm() {
    $('.popupEnrollForm').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#name',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#name';
				}
			}
		}
	});
}

function popupOrderCalcForm() {
    $('.popupOrderCalcForm').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#name',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#name';
				}
			}
		}
	});
}

function popupFeedForm() {
    $('.popupFeedForm').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#name',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#name';
				}
			}
		}
    });
}

/********************************
******** END Form Valid *********
********************************/

function initMap() {
    var mapCenter = $('meta[name="contacts:map-center"]').attr('content');

    ymaps.ready(function () {
        var myMap = new ymaps.Map('map', {
			center: mapCenter.split(','),
			controls: ['zoomControl'],
            zoom: 16
        }, {
            searchControlProvider: 'yandex#search'
        });

		myMap.behaviors.disable(['scrollZoom', 'rightMouseButtonMagnifier', 'multiTouch']);
		
		//myMap.controls.remove(['zoomControl', 'rulerControl']);

    	for (var key in places) {

    		placesExec = new ymaps.Placemark(
    		    places[key],
    		    {
					balloonContent: '<div class="mcontent">' + places[key][2] + '</div>'

    		    },
    		    {
					hideIconOnBalloonOpen : false,
                    preset: 'islands#blueHomeCircleIcon'
                    // iconColor: '#911800'
    		    }
    		);
			myMap.geoObjects.add(placesExec);
			placesExec.balloon.open();
    	}
    });
}


x.exe['option-default'] = ['placeholder()', 'sendMsg()'];
x.exe['option-index'] = ['initMap()', 'popupFreeConsForm()', 'popupEnrollForm()', 'execFreeConsForm()', 'execEnrollForm()'];
x.exe['option-catalog'] = ['sort()', 'popupFreeConsForm()', 'execFreeConsForm()'/*, 'catalog()'*/];
x.exe['option-catalog-item'] = ['execGallery()', 'popupFreeConsForm()', 'execFreeConsForm()', 'initMap()'],
x.exe['option-contacts'] = ['initMap()', 'popupFeedForm()', 'execFeedForm()'],
x.exe['option-commercial'] = ['initMap()', 'popupEnrollForm()', 'execFreeConsForm()', 'execEnrollForm()'];
x.exe['option-method'] = ['initMap()', 'showMagnImage()', 'popupEnrollForm()', 'popupOrderCalcForm()', 'execEnrollForm()', 'execOrderForm()', 'execOrderCalcForm()', 'execOrderSaleForm()'];
// x.exe['option-contacts'] = ['initMap()', 'execContactsForm()', 'sendMsg()'];
x.exe['option-mortgage'] = ['initMap()', 'execFreeConsForm()', 'execMortgageForm()'];
x.exe['option-selection'] = ['initMap()', 'costSliderSelectionExec()', 'execCatalogForm()', 'execSelectForm()'];
x.exe['option-services'] = ['initMap()', 'popupFreeConsForm()', 'popupEnrollForm()', 'execFreeConsForm()', 'execEnrollForm()', 'execOrderForm()'];
x.exe['option-team'] = ['initMap()', 'popupFreeConsForm()', 'popupEnrollForm()', 'execFreeConsForm()', 'execEnrollForm()', 'execOrderForm()'];
x.exe['option-novostroy'] = ['callRequest()'];
x.exe['option-novo-item'] = ['execGallery()', 'sort()', 'layoutView()', 'execFreeConsForm()', 'popupFreeConsForm()', 'callRequest()'];
x.exe['option-novo-flat'] = ['initMap()', 'callRequest()'];